import { TFunction } from 'i18next'
import { z } from 'zod'

export const createEditPlacementSchema = (t: TFunction) =>
  z
    .object({
      id: z.string().optional(),
      applicantId: z.string(),
      jobStageId: z.string(),
      status: z
        .object({
          value: z.string({
            required_error: `${t('form:requiredField')}`
          }),
          dot: z.any().optional(),
          supportingObj: z.object({ name: z.string() }).optional()
        })
        .optional(),
      hiredDate: z.date({
        required_error: `${t('form:requiredField')}`
      }),
      onboardDate: z.date().optional(),
      endOfProbationDate: z.date().optional(),
      salary: z.string().optional(),
      typeOfSalary: z.string().optional(),
      fee: z.string().optional(),
      typeOfFee: z.string().optional(),
      revenue: z.string().optional(),
      currencyOfRevenue: z.string().optional(),
      profitSplits: z.array(
        z.object({
          id: z.number().optional(),
          user_id: z.object(
            {
              value: z.string({
                required_error: `${t('form:requiredField')}`
              }),
              avatar: z.any().optional(),
              avatarVariants: z.any().optional(),
              defaultColour: z.string().optional(),
              supportingObj: z
                .object({ name: z.string().optional() })
                .optional()
            },
            { required_error: `${t('form:requiredField')}` }
          ),
          profit_percentage: z
            .string({
              required_error: `${t('form:requiredField')}`
            })
            .refine((percent) => Number(percent || '0') > 0, {
              message: `${t('form:profitMustBeGreaterThan0')}`
            }),
          _destroy: z.boolean().optional()
        })
      )
    })
    .superRefine((data, ctx) => {
      if (data.typeOfFee === 'percentage') {
        if (data.fee) {
          if (+data.fee < 0) {
            ctx.addIssue({
              code: 'custom',
              message: `${t('form:cannotInputNegative')}`,
              path: ['fee']
            })
          }
          if (+data.fee > 100) {
            ctx.addIssue({
              code: 'custom',
              message: `${t('form:maximum100Percent')}`,
              path: ['fee']
            })
          }
        }
      }
      if (data.endOfProbationDate && data.onboardDate) {
        if (data.endOfProbationDate < data.onboardDate) {
          ctx.addIssue({
            code: 'custom',
            message: 'End date cannot be earlier than start date.',
            path: ['endOfProbationDate']
          })
        }
      }
      if (data.typeOfFee === 'flat') {
        if (data.fee && +data.fee < 0)
          ctx.addIssue({
            code: 'custom',
            message: `${t('form:floatTypeCannotInputNegativeNumber')}`,
            path: ['fee']
          })
        if (data.fee && !data.fee.match(/^[1-9]\d*([\,\.]\d{1,2})?$/)) {
          ctx.addIssue({
            code: 'custom',
            message: `${t('form:flatFeeMustNoMoreThan2NumberBehindTheComma')}`,
            path: ['fee']
          })
        }
      }
      if (data.typeOfFee === 'months') {
        if (data.fee && +data.fee < 0)
          ctx.addIssue({
            code: 'custom',
            message: `${t('form:floatTypeExampleMonth')}`,
            path: ['fee']
          })
      }
      if (
        data.profitSplits.length > 0 &&
        !!data.profitSplits.find((item) => !item._destroy)
      ) {
        const totalPercentage = data.profitSplits
          .filter((item) => !item._destroy)
          .map((item) => +(item.profit_percentage || '0'))
          .reduce((acc, curr) => Number(acc) + Number(curr), 0)
        if (totalPercentage !== 100) {
          ctx.addIssue({
            code: 'custom',
            message: `${t('form:profitSumMustBeEqual100Percent')}`,
            path: ['profitSplits']
          })
        }
      }
    })

export type ICreateEditPlacement = z.infer<
  ReturnType<typeof createEditPlacementSchema>
>
