import { gql } from 'urql'
import { IPlacement } from '../../placement'

export const editPlacementMutation = gql<
  {
    placementsUpdate: {
      placement: IPlacement
    }
  },
  Partial<{
    id: number
    applicantId: number
    jobStageId: number
    status?: string
    hiredDate: string
    onboardDate?: string | null
    endOfProbationDate?: string | null
    salary?: number | null
    typeOfSalary?: string
    currencyOfSalary: string
    fee?: number | null
    typeOfFee?: string
    revenue?: number | null
    currencyOfRevenue: string
    profitSplits?: { userId: number; profitPercentage: number }[]
  }>
>`
  mutation (
    $id: Int!
    $applicantId: Int!
    $jobStageId: Int!
    $status: PlacementStatus
    $hiredDate: ISO8601DateTime!
    $onboardDate: ISO8601DateTime
    $endOfProbationDate: ISO8601DateTime
    $salary: Float
    $typeOfSalary: PlacementTypeOfSalary
    $currencyOfSalary: PlacementCurrencyOfRevenue
    $fee: Float
    $typeOfFee: PlacementTypeOfFee
    $revenue: Float
    $currencyOfRevenue: PlacementCurrencyOfRevenue
    $profitSplits: [JSON!]
  ) {
    placementsUpdate(
      input: {
        id: $id
        applicantId: $applicantId
        jobStageId: $jobStageId
        status: $status
        hiredDate: $hiredDate
        onboardDate: $onboardDate
        endOfProbationDate: $endOfProbationDate
        salary: $salary
        typeOfSalary: $typeOfSalary
        currencyOfSalary: $currencyOfSalary
        fee: $fee
        typeOfFee: $typeOfFee
        revenue: $revenue
        currencyOfRevenue: $currencyOfRevenue
        profitSplits: $profitSplits
      }
    ) {
      placement {
        id
        status
        hiredDate
        onboardDate
        endOfProbationDate
        salary
        typeOfSalary
        currencyOfSalary
        fee
        typeOfFee
        revenue
        currencyOfRevenue
        applicant {
          id
          createdAt
          hiredDate
          jobStage {
            id
          }
        }
        profitSplits {
          id
          profitPercentage
          user {
            id
            fullName
            avatarVariants
            defaultColour
          }
        }
        company {
          id
          name
        }
      }
    }
  }
`
